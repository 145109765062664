import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { ErrorInterface } from '@interfaces/errors/error.interface';
import { Config, Platform } from '@ionic/angular';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { Big } from 'big.js';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { vsprintf } from 'sprintf-js';
import { BasicObservableService } from '../shared/services/basic-observable/basic-observable.service';
import { ConfigsService } from '../shared/services/config/config.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subPushNotifications: Subscription;
  private subErrors: Subscription;

  languages = ['es', 'ca'];

  constructor(
    private readonly config: Config,
    private readonly authService: AuthService,
    private readonly errorsService: ErrorsService,
    private readonly toasterIonic: IonicToasterService,
    private readonly platform: Platform,
    private translate: TranslateService,
    private storage: LocalStorageService,
    private configService: ConfigsService,
    private basicObservableService: BasicObservableService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeTranslate();
    Big.DP = 10;
    Big.RM = 0;
    this.configService.getJSONConfig();
    registerLocaleData(localeEs, 'es');
    this.subErrors = this.errorsService
      .getObservable('errors')
      .pipe(
        filter((errors) => errors?.length > 0),
        map((errors) => errors[errors.length - 1]),
      )
      .subscribe((error: ErrorServiceInterface) => this.onError(error));
    this.authService.loadTokens();
    await this.platform.ready();
    this.initializeApp();
  }

  initializeTranslate() {
    this.translate.addLangs(this.languages);
    const currentLang = this.getCurrentLang();

    this.translate.setDefaultLang(currentLang);
    this.translate.use(currentLang);
    this.storage.create({
      key: 'language',
      value: this.translate.store.defaultLang.toLocaleUpperCase(),
    });
  }

  getCurrentLang(): string {
    const defaultLang = 'es';
    const browserLang = this.translate.getBrowserLang();
    console.log(browserLang);
    return (
      this.languages.find((language) => language === browserLang) ?? defaultLang
    );
  }

  async initializeApp() {
    this.config.set(
      'backButtonIcon',
      '/assets/extern/custom-ion-icons/ic_back_header.svg',
    );
  }

  protected onError(error: ErrorServiceInterface): void {
    const errors: ErrorInterface[] = error.payload?.error?.errors;

    const ignoreErrorSections = [
      'errors.stats-balances.standard',
      'errors.operations.otp',
    ];

    if (ignoreErrorSections.includes(error.section)) {
      return;
    }

    if (!errors) {
      console.error('Something fails');
      console.error(error);
      this.toasterIonic.presentToastWithOptions(
        this.translate.instant('admin.otp.operationFailed', {}),
        'error-toastr',
      );
    } else {
      errors.forEach((singleError) => {
        // Translate
        const key = singleError.message;
        const msg = vsprintf(key, singleError.values);
        this.toasterIonic.presentToastWithOptions(msg, 'error-toastr');
      });
    }

    this.errorsService.remove(error.id);
  }

  ngOnDestroy() {}
}
